'use client'
import BlockHeader from '@/components/UIkit/BlockHeader'
import { useEffect, useState } from 'react'
import ReactPlayer from 'react-player' // Імпорт react-player
import ModalForm from '../Modal/Modal'

export interface AdvantagesCustomV2Props {
  id?: string
  color?: 'violet' | 'green' | 'blue'
  title: string
  subtitle: string
  videoLink?: string
  youtubeLink?: string
  list: Array<{
    title: string
    subtitle: string
  }>
  showButton?: boolean
  buttonText?: string
  btnTag?: string
}

export default function AdvantagesCustomV2({
  title,
  subtitle,
  color = 'green',
  list,
  id,
  youtubeLink = 'https://youtu.be/IhLKObYe9c8',
  videoLink = 'https://academy.ap.education/assets/video/promoEng.mp4', // Зміна за замовчуванням на YouTube посилання
  showButton,
  buttonText,
  btnTag,
}: AdvantagesCustomV2Props) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isFormOpen, setIsFormOpen] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % list.length)
    }, 3000) // Adjust the timing as needed

    return () => clearInterval(interval)
  }, [list.length])

  const openForm = () => setIsFormOpen(true)
  const closeForm = () => setIsFormOpen(false)

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeModal()
    }
  }

  const backgroundColorClass =
    {
      green: 'custom-gradient-green-btn',
      blue: 'custom-gradient-blue-btn',
      violet: 'custom-gradient-violet-btn',
    }[color] || 'custom-gradient-green-btn'

  return (
    <>
      <div id={id} data-meta="AdvantagesCustomV2">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <BlockHeader title={title} subtitle={subtitle} />
          <div className="flex flex-col gap-4 justify-center items-center lg:grid lg:grid-cols-12 lg:gap-8">
            <div
              className="order-2 content-center mask-dir large rounded-3xl lg:order-1 lg:col-start-1 lg:col-end-7 lg:gap-6"
              onClick={openModal} // Клік по елементу відкриває модалку
            >
              <video
                className="w-full h-full object-cover max-lg:rounded-3xl cursor-pointer"
                src={videoLink}
                title="YouTube video player"
                loop
                playsInline
                autoPlay
                controls={false}
                muted
              ></video>
            </div>
            <div className="lg:block lg:order-2 lg:col-start-7 lg:col-end-13 mt-8">
              {list.map((item, index) => (
                <div
                  key={index}
                  className={`vertical-divider flex justify-start ${
                    index === 0 ? `first` : ''
                  } ${index === list.length - 1 ? 'last' : ''} ${
                    activeIndex === index ? 'white active' : ''
                  }`}
                >
                  <div className="flex text-white flex-col lg:items-start mb-6">
                    <p className="text-xl font-bold">{item.title}</p>
                    <p className="block text-sm">{item.subtitle}</p>
                  </div>
                </div>
              ))}
              {showButton && (
                <div className="flex justify-center lg:justify-start mt-4">
                  <button
                    onClick={openForm}
                    className={`${backgroundColorClass} uppercase rounded-full px-8 py-2 text-center text-md font-fixel font-bold text-white focus-visible:outline`}
                  >
                    {buttonText}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {isFormOpen && (
        <ModalForm closeForm={closeForm} btnMeta={{ tag: btnTag ?? '' }} color={color} />
      )}
      {isModalOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
          onClick={handleOverlayClick} // Закриття при кліку поза межами модалки
        >
          <div className="relative w-full max-w-3xl bg-white rounded-3xl">
            <button
              className="absolute top-2 right-2 text-white text-2xl z-50 focus:outline-none"
              onClick={closeModal} // Закриття по кліку на хрестик
            >
              &times;
            </button>
            <div className="w-full h-96 lg:min-h-96">
              <ReactPlayer
                url={youtubeLink}
                width="100%"
                height="100%"
                playing={true}
                loop={true}
                muted={false}
                controls={true}
                style={{
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

'use client'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { JSX, useState } from 'react'
import ModalForm from '../Modal/Modal'

export interface HeroBlockCustomProps {
  title: string
  videoLink_MP4?: string
  sub_line_1?: {
    title: string
    icon: string
  }
  sub_line_2?: {
    title: string
    icon: string
  }
  sub_line_3?: {
    title: string
    icon: string
  }
  btnText?: React.JSX.Element
  btnContent?: React.JSX.Element
  btnContentSM?: React.JSX.Element
  btnMeta?: {
    tag: string
  }
  videoLink_WEBM?: string
  color?: 'green' | 'blue' | 'violet'
  poster?: string
}

export default function HeroBlockCustom({
  title,
  videoLink_MP4 = 'https://stage.ap.educationhttps://academy.ap.education/assets/video/hero1.mp4',
  videoLink_WEBM,
  btnText = <span>Консультація</span>,
  btnContent = (
    <p>
      Отримати детальнішу <br />
      інформацію
    </p>
  ),
  btnContentSM = <p>Отримати детальнішу інформацію</p>,
  btnMeta,
  color = 'green',
  poster = '/assets/cashFile/videoPosterGreen.png',
}: HeroBlockCustomProps) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isFormOpen, setIsFormOpen] = useState(false)
  const [loading, setLoading] = useState(true) // Track loading state for the video

  const backgroundColorClass =
    {
      green: 'custom-gradient-green-btn',
      blue: 'custom-gradient-blue-btn',
      violet: 'custom-gradient-violet-btn',
    }[color] || 'custom-gradient-green-btn'

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  const openForm = () => setIsFormOpen(true)
  const closeForm = () => setIsFormOpen(false)

  // Handle video loading status
  const handleVideoCanPlay = () => {
    setLoading((prevState) => !prevState) // Video is ready to play, hide spinner
  }

  return (
    <>
      <div
        data-meta="HeroBlockCustom"
        className="container max-w-full relative mx-auto mt-8 xl:mt-12"
      >
        <div className="relative mx-auto max-w-6xl  text-center">
          <h1 className="text-3xl sm:text-6xl font-bold text-white lg:text-6xl">{title}</h1>
        </div>
        <div className="relative max-w-7xl mx-auto">
          <div className="lg:-top-20">
            <div className="relative h-full">
              <video
                loop
                muted
                autoPlay
                playsInline
                className="mask large object-cover object-center w-full h-full"
                poster={poster}
                title="AP Education"
                preload="auto"
                aria-hidden="true"
                controls={false}
              >
                <source src={videoLink_MP4} type="video/mp4" />
                {videoLink_WEBM && <source src={videoLink_WEBM} type="video/webm" />}
                Your browser does not support the video tag.
              </video>
              <div className=" hidden lg:block absolute bottom-[13%] right-1 sm:right-4 z-10">
                <div className="flex md:gap-2 xl:gap-12 items-center">
                  <div className="text-xs xl:text-base text-white">{btnContent}</div>
                  <button
                    onClick={openForm}
                    className={`uppercase ${backgroundColorClass} text-white rounded-full px-8 py-2.5 text-center text-sm font-fixel font-bold`}
                  >
                    {btnText}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:hidden font-fixel py-4 text-white text-md rounded-3xl flex flex-col justify-center items-center gap-4">
          {btnContentSM}
          <button
            onClick={openForm}
            className={`${backgroundColorClass}  uppercase text-white rounded-full px-8 py-2.5 text-center text-sm font-fixel font-bold`}
          >
            {btnText}
          </button>
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
          <div className="relative w-full h-fit max-w-7xl flex justify-center">
            <video
              className="object-contain rounded-3xl"
              src={videoLink_MP4}
              title="Video player"
              loop
              playsInline
              autoPlay
              controls
            ></video>
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 text-white bg-black bg-opacity-50 rounded-full p-2"
            >
              <XMarkIcon className="h-8 w-8" />
            </button>
          </div>
        </div>
      )}

      {isFormOpen && <ModalForm closeForm={closeForm} btnMeta={btnMeta} color={color} />}
    </>
  )
}

"use client";

import ProposalCard from "@/components/Proposal/ProposalCard";
import { ArrowUpRightIcon } from "@heroicons/react/16/solid";
import { useState } from "react";
import ModalForm from "../Modal/Modal";
export interface ProposalCardBlockProps {
  id?: string;
  title: string;
  subtitle?: string;
  title_2?: string;
  cardList: Array<{
    title: string;
    subtitle?: string;
    link?: string;
    icon?: string;
  }>;
  color?: "violet" | "green" | "blue";
  showBtn?: boolean;
  btnTag?: string;
}

export default function ProposalCardBlock({
  title,
  subtitle,
  cardList,
  id,
  color = "green",
  title_2,
  showBtn = false,
  btnTag,
}: ProposalCardBlockProps) {
  const [isFormOpen, setIsFormOpen] = useState(false);

  const openForm = () => setIsFormOpen(true);
  const closeForm = () => setIsFormOpen(false);

  const backgroundColorClass =
    {
      green: "custom-gradient-green",
      blue: "custom-gradient-blue",
      violet: "custom-gradient-violet",
    }[color] || "custom-gradient-green";
  return (
    <>
      <div
        id={id}
        data-meta="ProposalCardBlock"
        className={`container mx-auto sm:px-5 my-5 flex flex-col justify-center rounded-3xl`}
      >
        <h2 className="text-center text-3xl font-bold tracking-tight text-white sm:text-6xl mb-4 sm:mb-8">
          {title}
        </h2>
        {subtitle && (
          <p className="text-center mt-6 text-base lg:text-lg md:leading-6 lg:leading-8 text-white">
            {" "}
            {subtitle}{" "}
          </p>
        )}
        {title_2 && (
          <p className="mt-4 text-3xl font-bold text-white text-center">
            {" "}
            {title_2}{" "}
          </p>
        )}
        <div className="grid grid-cols-1 gap-2 lg:grid-cols-2 lg:gap-4 py-14">
          {cardList.map((item) => (
            <ProposalCard
              key={item.title}
              icon={item.icon}
              title={item.title}
              subtitle={item.subtitle}
              link={item.link}
            />
          ))}
        </div>
        {showBtn && (
          <div className="flex justify-center">
            <button
              onClick={openForm}
              className={`pl-8 pr-1 py-1 text-lg font-bold text-white uppercase rounded-full  ring-violet-500 ring-1 flex gap-2 items-center`}
            >
              <span className="max-sm:text-sm">
                Дізнатися більше про програму
              </span>
              <div className={`rounded-full ${backgroundColorClass}`}>
                <ArrowUpRightIcon className="text-white h-4 w-4 m-2" />
              </div>
            </button>
          </div>
        )}
      </div>
      {isFormOpen && (
        <ModalForm
          closeForm={closeForm}
          btnMeta={{ tag: btnTag ?? "" }}
          color={color}
        />
      )}
    </>
  );
}

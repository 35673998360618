"use client";
import dynamic from "next/dynamic";
const ReviewsBlockCustom = dynamic(
  () => import("@/components/Reviews/ReviewsBlockCustom"),
  {
    ssr: false,
  },
);
export default function ReviewsBlockCustomClient() {
  return <ReviewsBlockCustom />;
}

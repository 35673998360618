import { ArrowUpRightIcon } from "@heroicons/react/24/outline";
import Link from "next/link";
import Image from "next/image";

export interface ProposalCardProps {
  icon?: string;
  title: string;
  subtitle?: string;
  link?: string;
  color?: "violet" | "green" | "blue";
}
function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

export default function ProposalCard({
  title,
  subtitle,
  icon,
  link,
  color = "green",
}: ProposalCardProps) {
  const backgroundColorClass =
    {
      green: "custom-gradient-green-btn-h",
      blue: "custom-gradient-blue-btn-h",
      violet: "custom-gradient-violet-btn-h",
    }[color] || "custom-gradient-green-btn-h";
  return (
    <div
      data-meta="ProposalCard"
      className={`flex p-4 xl:p-8 gap-12 backdrop-blur-sm bg-white/30 rounded-3xl ${
        icon?.length ? "" : "justify-between"
      }`}
    >
      <div
        className={`flex items-start justify-center text-white ${
          icon?.length ? "" : "order-2"
        }`}
      >
        {icon && (
          <div className="flex justify-center items-center backdrop-blur-sm bg-white/30 rounded-full h-16 w-16">
            <Image src={icon} width={32} height={32} alt={""} />
          </div>
        )}
        {link && (
          <Link
            className={`${backgroundColorClass} uppercase flex justify-center items-center rounded-full border hover:border-0 border-white h-16 w-16`}
            href={link}
          >
            <ArrowUpRightIcon className="text-white h-6 w-6" />
          </Link>
        )}
      </div>
      <div
        className={classNames(
          icon ? "" : "order-1 justify-start",
          subtitle ? "lg:items-start" : "lg:items-center",
          "text-white flex flex-col justify-center"
        )}
      >
        <p
          className={classNames(
            subtitle?.length ? "md:mb-6" : "",
            "text-xl font-bold"
          )}
        >
          {title}
        </p>
        {subtitle && <p className="md:block">{subtitle}</p>}
      </div>
    </div>
  );
}

'use client'
import Link from 'next/link'
import { useState } from 'react'

export interface AdvantagesCustomV3Props {
  id?: string
  logoUrl?: string
  color?: 'violet' | 'green' | 'blue'
  topSubtitle?: string
  topSubtitle_2?: string
  title: string
  title_2?: string
  subtitle?: string
  videoLink?: string
  youtubeLink?: string
  sideText: React.ReactNode
  buttonText: string
  buttonLink: string
}

export default function AdvantagesCustomV3({
  id,
  color = 'green',
  topSubtitle,
  topSubtitle_2,
  title,
  title_2,
  subtitle,
  videoLink = 'https://academy.ap.education/assets/video/promoEng.mp4',
  sideText,
  buttonText,
  buttonLink,
}: AdvantagesCustomV3Props) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isFormOpen, setIsFormOpen] = useState(false)

  const openForm = () => setIsFormOpen(true)
  const closeForm = () => setIsFormOpen(false)

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeModal()
    }
  }

  const backgroundColorClass =
    {
      green: 'custom-gradient-green-btn',
      blue: 'custom-gradient-blue-btn',
      violet: 'custom-gradient-violet-btn',
    }[color] || 'custom-gradient-green-btn'

  return (
    <div id={id} data-meta="AdvantagesCustomV3">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 my-14">
        <div className="flex flex-col gap-12">
          <div className="flex flex-col-reverse md:flex-row md:justify-between gap-12">
            <div className="flex flex-col flex-grow gap-4">
              {(topSubtitle || topSubtitle_2) && (
                <div className="flex flex-col sm:flex-row sm:justify-between">
                  <span className="text-white text-xl">{topSubtitle}</span>
                  <span className="text-white text-xl uppercase font-bold lg:transform lg:translate-y-[30px]">
                    {topSubtitle_2}
                  </span>
                </div>
              )}
              <h2 className="flex gap-2 font-bold">
                <p className="text-white text-4xl">{title} </p>
                {title_2 && <p className={`custom-gradient-${color}-text text-4xl`}>{title_2}</p>}
              </h2>
              <p
                className={`text-white ${
                  id === 'conf-university'
                    ? 'self-end text-right italic whitespace-pre-line w-full sm:w-2/3 '
                    : 'self-center text-center w-full lg:w-2/3'
                }`}
              >
                {subtitle}
              </p>
            </div>
          </div>

          <div className="flex flex-col lg:flex-row gap-6 lg:gap-12">
            <div className="lg:w-1/2 mask-dir large">
              <video
                className="w-full h-full object-cover max-lg:rounded-3xl"
                src={videoLink}
                loop
                playsInline
                autoPlay
                controls={false}
                muted
              ></video>
            </div>
            <div className="lg:w-1/2">
              <div className="text-white">{sideText}</div>
            </div>
          </div>

          <Link
            href={buttonLink}
            className={`${backgroundColorClass} uppercase text-white rounded-full py-4 px-6 sm:px-14 w-fit font-semibold m-auto`}
          >
            {buttonText}
          </Link>
        </div>
      </div>
    </div>
  )
}

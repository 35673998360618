"use client";
import axios from "axios";
import dynamic from "next/dynamic";
import { useRouter } from "next/navigation";
import { useState } from "react";
import { z } from "zod";
const PhoneInput = dynamic(() => import("react-phone-input-2"), { ssr: false });
export interface ContactBlockCustomV2Props {
  color?: "violet" | "green" | "blue";
  title?: string;
  subtitle?: string;
  btnTag?: string;
  link?: string;
}

// Створюємо схему валідації за допомогою zod
const contactFormSchema = z.object({
  fields_1: z
    .string()
    .min(1, "Ім’я та прізвище обов'язкові")
    .max(100, "Занадто довге ім’я"),
  phone: z
    .string()
    .min(1, "Номер телефону обов'язковий")
    .regex(/^\+?[0-9]{10,14}$/, "Невірний формат номера телефону"),
  date: z.string().optional(),
  tag: z.string().optional(),
});
type FormFields = {
  fields_1: string;
  phone: string;
  date?: string;
};

export default function ContactBlockCustomV2({
  color = "green",
  title = "Бажаєте долучитися до навчання?",
  subtitle,
  btnTag,
  link = "https://ap-server-8qi1.onrender.com/leads",
}: ContactBlockCustomV2Props) {
  const [formData, setFormData] = useState({
    fields_1: "",
    phone: "",
    date: "",
    tag: btnTag,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState({
    fields_1: "",
    phone: "",
    date: "",
  });
  const router = useRouter();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    // Валідація даних форми
    const validationResult = contactFormSchema.safeParse(formData);
    if (!validationResult.success) {
      // Відображаємо помилки для кожного поля
      const newErrors: FormFields = { fields_1: "", phone: "", date: "" };

      validationResult.error.errors.forEach((curr) => {
        const fieldName = curr.path[0] as keyof FormFields; // Вказуємо тип
        newErrors[fieldName] = curr.message;
      });

      setErrors({ date: "", ...newErrors });
      setLoading(false);
      return;
    }

    const utmTags = JSON.parse(localStorage.getItem("utmTags") || "") || {};
    try {
      await axios.post(link, {
        name: formData.fields_1,
        phone: formData.phone,
        time: formData.date,
        tag: formData.tag,
        ...utmTags,
      });
      router.push("/thankyou");
      setSuccess(true);
    } catch (error) {
      setError("Форма не відправлена, спробуйте будь ласка ще раз.");
    } finally {
      setLoading(false);
    }
  };

  const handlePhoneChange = (value: string) => {
    setFormData({
      ...formData,
      phone: value,
    });
    setErrors({ ...errors, phone: "" });
  };

  const backgroundColorClass =
    {
      green: "custom-gradient-green-btn",
      blue: "custom-gradient-blue-btn",
      violet: "custom-gradient-violet-btn",
    }[color] || "custom-gradient-green-btn";
  return (
    <div
      data-meta="ContactBlockCustomV2"
      className="relative mt-16 lg:m-8 lg:mt-24"
    >
      <div className="relative flex max-lg:justify-center lg:flex-col mx-auto max-w-7xl isolate max-lg:mx-6">
        <div className=" p-10 sm:p-20 md:pt-24 md:pb-16 lg:pt-12 lg:pb-20 lg:px-20 xl:p-28 h-max directory-v2 w-max rounded-3xl">
          <div className="lg:mt-6 lg:mb-12 mb-6">
            <p className="mb-4 text-3xl text-center font-bold tracking-tight text-gray-900 sm:text-4xl">
              {title}
            </p>
            {subtitle && (
              <p className="text-center max-lg:max-w-md max-lg:mx-auto ">
                {subtitle}
              </p>
            )}
          </div>
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            className="flex flex-col w-full justify-center items-center lg:grid lg:grid-cols-12 md:gap-4 max-lg:max-w-md max-lg:mx-auto"
          >
            <div className="col-span-10 flex flex-col lg:flex-row lg:gap-4 justify-between items-center max-w-7xl w-full max-lg:mb-6">
              <div className="col-span-4 w-full">
                <label
                  htmlFor="fields_1"
                  className="block text-sm font-semibold leading-6 text-gray-900 sr-only"
                >
                  Ваше ім’я та прізвище*
                </label>
                <div className="mt-2.5 lg:relative">
                  <input
                    type="text"
                    name="fields_1"
                    id="fields_1"
                    placeholder="Ім'я"
                    autoComplete="off"
                    className={`mt-1 block w-full px-3 py-2 border border-gray-300 rounded-full focus:shadow-md focus:border-gray-300 focus:ring-0  sm:text-sm`}
                    value={formData.fields_1}
                    onChange={handleChange}
                    required
                  />
                  {errors.fields_1 && (
                    <p className="mt-1 text-red-500 text-sm lg:absolute lg:bottom-0 lg:pr-2 lg:translate-y-[100%]">
                      {errors.fields_1}
                    </p>
                  )}
                </div>
              </div>
              <div className="shrink col-span-4  w-full">
                <label
                  htmlFor="phone"
                  className="block text-sm font-semibold leading-6 text-gray-900 sr-only"
                >
                  Телефон
                </label>
                <div className="mt-2.5 lg:relative">
                  <PhoneInput
                    country={"ua"}
                    excludeCountries={["ru"]}
                    value={formData.phone}
                    placeholder="Телефон"
                    onChange={handlePhoneChange}
                    containerClass={`mt-1 block w-full`}
                    inputClass={`w-full px-3 py-2 rounded-full   border ${
                      errors.phone ? "border-red-500" : "border-gray-300"
                    }  sm:text-sm focus:shadow-md focus:border-gray-300 focus:ring-0`}
                    buttonClass="hover:bg-inherit focus:bg-inherit bg-inherit border-l-0 border-gray-300"
                    inputProps={{
                      name: "phone",
                      id: "phone",
                      required: true,
                    }}
                    containerStyle={{
                      borderColor: "#fff",
                    }}
                    inputStyle={{
                      width: "100%",
                      height: "2.25rem",
                      fontSize: "0.875rem",
                      backgroundColor: "#fff",
                      borderRadius: "2rem",
                    }}
                    buttonStyle={{
                      backgroundColor: "inherit",
                      borderRadius: "2rem 0 0 2rem",
                      borderWidth: "1px",
                      borderColor: "#e2e8f0",
                    }}
                  />
                  {errors.phone && (
                    <p className="mt-1 text-red-500 text-sm lg:absolute lg:bottom-0 lg:pr-2 lg:translate-y-[100%]">
                      {errors.phone}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-span-4  w-full">
                <label htmlFor="date" className="sr-only">
                  Коли вам зателефонувати?
                </label>
                <div className="mt-2.5 lg:relative">
                  <input
                    autoComplete="off"
                    id="date"
                    name="date"
                    type="text"
                    placeholder="Коли вам зателефонувати?"
                    value={formData.date}
                    onChange={handleChange}
                    className={`mt-1 block w-full px-3 py-2 border border-gray-300 rounded-full focus:shadow-md focus:border-gray-300 focus:ring-0  sm:text-sm`}
                  />
                </div>
              </div>
            </div>
            <div className="mt-2.5 max-lg:col-span-full">
              <button
                type="submit"
                disabled={loading}
                className={`${backgroundColorClass} uppercase rounded-full px-8 py-2.5 text-center text-md font-fixel font-bold text-white shadow-sm focus-visible:outline`}
              >
                {loading ? "Надсилання..." : "Надіслати"}
              </button>
            </div>
          </form>
          {error && <p className="mt-1 text-red-500 text-sm">{error}</p>}
          {success && (
            <p className="mt-2 text-green-500 text-sm">
              Форма успішно надіслана!
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

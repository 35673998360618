"use client";
import AchievementItemV4 from "./AchievementItemV4";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Navigation, Autoplay } from "swiper/modules";
import { useRef } from "react";

SwiperCore.use([Navigation, Autoplay]);

interface AchievementsListProps {
  achievementsList: Array<{
    icon?: string;
    title: string;
    subtitleImg: string;
    hoverTxt?: string;
    width: number;
    height: number;
  }>;
}

export default function AchievementsListV4({
  achievementsList,
}: AchievementsListProps) {
  const swiperRef = useRef<any>(null);

  const handleMouseEnter = () => {
    swiperRef.current.autoplay.stop();
  };

  const handleMouseLeave = () => {
    swiperRef.current.autoplay.start();
  };

  return (
    <div
      data-meta="AchievementsListV4"
      className="overflow-hidden relative mt-12"
    >
      <Swiper
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        modules={[Autoplay, Navigation]}
        spaceBetween={30}
        slidesPerView={4}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 4,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          960: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          1280: {
            slidesPerView: 4,
            spaceBetween: 10,
          },
          1600: {
            slidesPerView: 5,
            spaceBetween: 30,
          },
          1920: {
            slidesPerView: 5,
            spaceBetween: 10,
          },
        }}
        centeredSlides={true}
        loop={true}
        speed={2500}
        autoplay={{
          delay: 0,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {achievementsList.map((item, index) => (
          <SwiperSlide key={index} className="!flex !justify-center">
            <AchievementItemV4 achievementItem={item} swiperRef={swiperRef} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

"use client";
import { useState, useEffect } from "react";
import Image from "next/image";

interface AchievementItemProps {
  achievementItem: {
    icon?: string;
    title: string;
    subtitleImg: string;
    hoverTxt?: string;
    width: number;
    height: number;
  };
  swiperRef: React.RefObject<any>;
}

export default function AchievementItemV4({
  achievementItem,
  swiperRef,
}: AchievementItemProps) {
  const [isClicked, setIsClicked] = useState(false);
  const handleClick = () => {
    setIsClicked((prev) => {
      const newClickedState = !prev;
      if (newClickedState) {
        swiperRef.current.autoplay.stop();
      } else {
        swiperRef.current.autoplay.start();
      }
      return newClickedState;
    });
  };

  return (
    <div
      data-meta="AchievementItemV4"
      className={`achievement-item ${isClicked ? "clicked" : ""}`}
      onClick={() => handleClick()}
    >
      <div className="achievement-inner">
        <div className="achievement-front">
          <p className="font-fixel text-ellipsis text-center font-bold text-white flex justify-center items-center h-20 w-52 border-b">
            {achievementItem.title}
          </p>
          {achievementItem.subtitleImg &&
          achievementItem.subtitleImg.trim().length ? (
            <Image
              width={150}
              height={150}
              alt=""
              src={achievementItem.subtitleImg}
              className="mx-auto h-20 w-auto"
            />
          ) : (
            <div className="h-20 w-auto"></div>
          )}
        </div>
        <div
          className={
            "bg-white rounded-full achievement-back " +
            (!achievementItem.hoverTxt ? "achievement-back--large" : "")
          }
        >
          {achievementItem.icon && (
            <Image
              width={achievementItem.width}
              height={achievementItem.height}
              src={achievementItem.icon}
              alt=""
              className="object-contain"
            />
          )}
          {achievementItem.hoverTxt && (
            <p className="font-fixel text-center font-bold text-black">
              {achievementItem.hoverTxt}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

"use client";
import ReactPlayer from "react-player"; // Імпорт react-player
import ModalForm from "../Modal/Modal";
import { useState } from "react";

export interface VideoProps {
  videoUrl: string;
  youtubeLink?: string;
}

export default function Video({
  videoUrl,
  youtubeLink = "https://youtu.be/IhLKObYe9c8",
}: VideoProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };
  return (
    <>
      <div
        data-meta="Video"
        onClick={openModal}
        className="overflow-hidden rounded-3xl flex justify-center"
      >
        <video
          className="flex justify-center cursor-pointer w-full h-full object-cover"
          src={videoUrl}
          title="AP Education"
          loop
          playsInline
          autoPlay
          controls={false}
          muted
        ></video>
      </div>
      {isModalOpen && youtubeLink && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
          onClick={handleOverlayClick} // Закриття при кліку поза межами модалки
        >
          <div className="relative w-full max-w-7xl bg-white rounded-3xl">
            <button
              className="absolute top-2 right-2 text-white text-4xl z-50 focus:outline-none"
              onClick={closeModal} // Закриття по кліку на хрестик
            >
              &times;
            </button>
            <div className="relative aspect-video">
              <ReactPlayer
                url={youtubeLink}
                width="100%"
                height="100%"
                playing={true}
                loop={true}
                muted={false}
                controls={true}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
